import React, { Fragment, useEffect, useRef, useState } from "react";

import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { Link, useNavigate, useNavigation } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import swal from 'sweetalert';

import { BACKEND_SERVER_URL } from "../../../http/http-config";
import BooksModal from "./BookModal";
import axios from "axios";
import Select from "react-select";
import { Badge, Button, Spinner } from "react-bootstrap";

import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, showTableResponse } from "../common-helpers/commonServices";
import { toast } from "react-toastify";
import ProdutsDetailsTabsAtTop from "../Common-Components/ProdutsDetailsTabsAtTop";
import SearchBox from "../Common-Components/SearchBox";
import { getExamTypes } from "../Course/CourseHelper";

const Books = () => {
    const childRef = useRef();
    const navigate = useNavigate()
    const [tests, setTests] = useState([]);
    const [checked, setChecked] = useState(tests);
    const [unchecked, setUnChecked] = useState(true);
    const [loadCourses, setCourseLoading] = useState(false);
    const [selectedTests, setSelectedTests] = useState(tests);
    const [examWiseSelectedTests, setExamWiseSelectedTests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedExamType, setExamType] = useState('Exam wise selection')
    const [selectedSubjectType, setSubjectType] = useState('Course Type wise selection')
    const [uniqueExamBodies, setUniqueExamBodies] = useState([]);
    const [uniqueSubjects, setUniqueSubjects] = useState([]);
    const [totalActiveCourses, setTotalActiveCourses] = useState([]);
    const [totalInactiveCourses, setTotalInactiveCourses] = useState([]);
    const [totalArchivedCourses, setTotalUnlisedCourses] = useState([]);
    console.log(loadCourses)

    //practice
    //    let [e1,e2,e3,e4]= new Array(1,2,3,'',6)    
    //     console.log(e1,e2,e3,e4) 

    console.log(totalActiveCourses)
    console.log(totalInactiveCourses)
    console.log(totalArchivedCourses)


    // search functionality
    const [searchTerm, setSearchTerm] = useState('');
    useEffect(() => {
        const filteredCourses = tests?.filter(
            item => item?.title?.toLowerCase().includes(searchTerm?.toLowerCase())
        );
        setSelectedTests(filteredCourses)
    }, [searchTerm])
    const handleSearch = (e) => {
        setSearchTerm(e.target.value)
    }

    useEffect(() => {
        axios.get(BACKEND_SERVER_URL + '/getBooks').then((res) => {
            console.log(res.data.books)
            if (res.data) {
                let books = res.data.books;
                setTests(books)
                console.log(books)
                setSelectedTests(books.filter(course => course.hide == 0))
                setTotalActiveCourses(books.filter(course => course.status == 1 && course.hide == 0))
                setTotalInactiveCourses(books.filter(course => course.status == 0 && course.hide == 0))
                let archived = books.filter(course => course.hide == 1)
                setTotalUnlisedCourses(archived)
            }
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message);
        })
        getExamTypes().then(examTypes => {
            let uniqueExamTypes = [...new Set(examTypes.map(course => course.exambody))]
            uniqueExamTypes.unshift("All Exams")
            setUniqueExamBodies(uniqueExamTypes)
        })
    }, [loadCourses])

    const pageSize = 50;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = selectedTests.slice(firstIndex, lastIndex);

    const npage = Math.ceil(selectedTests.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)

    function splitCamelCase(word) {
        return word?.replace(/([a-z])([A-Z])/g, '$1-$2')
            .split('-')
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join(' ');
    }
    const selectExamHandler = (value) => {
        setExamType(value)
        setSubjectType('Type wise selection')
        if (value == "All Exams") {
            setExamWiseSelectedTests(tests)
            setSelectedTests(tests)
            setUniqueSubjects([]);
            return;
        }
        let examWiseSelectedTests = tests.filter((course) => course.examBody == value)
        setExamWiseSelectedTests(examWiseSelectedTests)
        setSelectedTests(examWiseSelectedTests)
        setUniqueSubjects([...new Set(tests.filter(course => course.examBody == value).map(test => test.type))]);


    }

    const selectSubjectHandler = (value) => {
        setSubjectType(value)
        let subjectWiseSelectedTests = examWiseSelectedTests.filter((test) => test.type == value)
        setSelectedTests(subjectWiseSelectedTests)
    }
    const handleShowAllCourses = () => {
        setSelectedTests(tests)
        setExamType('Exam wise selection');
        setSubjectType('Type wise selection');
        setUniqueSubjects([])
    }
    const handlePageChange = (e, p) => {
        setCurrentPage(parseInt(p));
    }

    const handleUnlisedTest = (courseId) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this course",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                axios.put(BACKEND_SERVER_URL + `/unlisedTest/${courseId}`).then(response => {
                    if (response.data.statusCode == 200) {
                        toast.success(response.data.message)
                        setCourseLoading(!loadCourses)
                    }
                }).catch((error) => {
                    console.log(error)
                    toast.error(error.response.data.message);
                })
            }
        })
    }
    return (
        <Fragment>
            <div className="col-xl-12">
                <div className=" page-title p-2 mb-2">
                    <div className=' col-8 d-flex justify-content-between'>
                        <div className="col-3 ">
                            <button type="button" className="btn btn-dark btn-sm "
                                onClick={() => childRef.current.openModal()}
                            >
                                + Add Book
                            </button>

                        </div>
                        {/* <div className="col-4">
                            <div className="basic-dropdown">
                                <Select
                                    value={{ value: selectedExamType, label: selectedExamType }}
                                    onChange={(selectedOption) => selectExamHandler(selectedOption.value)}
                                    options={uniqueExamBodies.map((examBody) => ({ value: examBody, label: examBody }))}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                            backgroundColor: 'transparent', // Adjust as needed
                                            borderColor: '#17a2b8', // Adjust as needed
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="basic-dropdown ms-3">
                                <Select
                                    value={{ value: selectedSubjectType, label: splitCamelCase(selectedSubjectType) }}
                                    onChange={(selectedOption) => selectSubjectHandler(selectedOption.value)}
                                    options={uniqueSubjects?.map((subject) => ({ value: subject, label: splitCamelCase(subject) }))}
                                    styles={{
                                        control: (provided) => ({
                                            ...provided,
                                            width: '100%',
                                            backgroundColor: 'transparent', // Adjust as needed
                                            borderColor: '#17a2b8', // Adjust as needed

                                        }),
                                    }}
                                />
                            </div>
                        </div> */}
                        {/*   <div className="col-2">
              <button type="button" className="btn btn-dark btn-xs ms-2 btn-block " onClick={handleShowAllCourses}> show All</button>
            </div> */}
                    </div>
                    <div className=" col-4 input-group search-area mb-md-0 mb-3 w-25 " >
                        <SearchBox setChecked={setSelectedTests} dataArray={tests} searchingProperty={'courseTitle'} />
                    </div>

                </div>
                <ProdutsDetailsTabsAtTop setChecked={setSelectedTests} totalItems={tests} totalActiveItems={totalActiveCourses} totalInactiveItems={totalInactiveCourses} totalArchivedItems={totalArchivedCourses} />
            </div>
            <div className="dataTables_wrapper no-footer">
                <div className="d-sm-flex text-center justify-content-between align-items-center">
                    <div>
                        <Button variant="danger ms-3" size='xs' onClick={(e) => bulkOperations("delete", records, '/hideBooks', 'Books', setCourseLoading, loadCourses)}>Delete</Button>
                        <Button variant="success ms-3 " size='xs' onClick={(e) => bulkOperations("activate", records, '/activateBooks', 'Books', setCourseLoading, loadCourses)}>Active</Button>
                        <Button variant="warning ms-3" size='xs' onClick={(e) => bulkOperations("InActivate", records, '/inactivateBooks', 'Books', setCourseLoading, loadCourses)}>Inactive</Button>
                    </div>
                    <div className='dataTables_info text-dark'>
                        Records: {selectedTests.length}
                    </div>
                    {selectedTests.length > pageSize ? <div className="">
                        <div className=""
                            id="example-student_wrapper">
                            <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={handlePageChange} />
                        </div>
                    </div> : null
                    }
                </div>
            </div>

            <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
                    <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                        <table className="table-responsive-lg table display dataTablesCard student-tab 
                                    dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
                            <thead style={{ backgroundColor: "#cccccc" }}>
                                <tr className='text-center text-dark border border-left'>
                                    <th className=' custom-table-header p-2'>SN</th>
                                    <th className=' custom-table-header p-2'>
                                        <input type="checkbox" className="form-check-input" id="checkAll"
                                            /*   checked={!unchecked} */
                                            onClick={() => handleCheckedAll(unchecked, selectedTests, setSelectedTests, setUnChecked)}
                                        />
                                    </th>
                                    <th className='p-2 text-start  custom-table-header'>STATUS</th>
                                    <th className=' text-start custom-table-header p-2' >TITLE</th>
                                    <th className=' text-start custom-table-header p-2' >TITLE HEADING</th>
                                    <th className=' text-start custom-table-header p-2' >EXAM</th>
                                    {/*   <th className='p-2 custom-table-header'>LESSIONS</th> */}
                                    <th className='p-2 custom-table-header'>SUBSCRIBERS</th>

                                </tr>
                            </thead>
                            <tbody>
                                {records.length > 0 ? records.map((item, ind) => (
                                    <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
                                        <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0"> {item.sn}</h6></td>
                                        <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                            <div className="checkbox me-0 align-self-center">
                                                <div className="custom-control custom-checkbox">
                                                    <input type="checkbox"
                                                        className="form-check-input"
                                                        id={`stud-${item.sn}`}
                                                        checked={item.inputchecked}
                                                        onChange={() => handleChecked(item.sn, setSelectedTests, selectedTests)}
                                                    />
                                                    <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
                                            {item.status === 1 && <h6 className="text-success mb-0 ">Active </h6>}
                                            {item.status === 0 && <h6 className="text-danger mb-0">Inactive </h6>}
                                        </td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                            <div className="d-flex justify-content-between ">
                                                <h6 onClick={() => navigate('/practice-tests', { state: { courseId: item.courseId,courseTitle:item.courseTitle} })} className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' ,cursor:"pointer"}} >
                                                    <span className="" >{item.courseTitle}</span>  {' '}
                                                    <CiEdit cursor='pointer' title='Edit Book' size={18} className='ms-3' onClick={() => childRef.current.openModal(item.courseId)} />
                                                    <MdDeleteOutline color='red' title='Delete Book' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.sn, 'Book', '/hideBook/', setCourseLoading, loadCourses) }}
                                                    />
                                                </h6>
                                            </div>
                                        </td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                            <div className="d-flex justify-content-between ">
                                                <h6 className='mb-0 '>{item.titleHeading}{' '}

                                                </h6>
                                            </div>
                                        </td>
                                        <td className='p-1' style={{ border: "1px solid #cccccc" }}>
                                            <div className="d-flex justify-content-between ">
                                                <h6 className='mb-0 '>{item.examBody}{' '}

                                                </h6>
                                            </div>
                                        </td>
                                        {/*    <td className='p-1 text-center active-col-width' style={{ border: "1px solid #cccccc", width: '80px' }}><h6 className="mb-0 ">{item.topicCount}</h6></td> */}
                                        < td className='p-1 text-center' style={{ border: "1px solid #cccccc", width: "65px" }}>
                                            <h6 className="mb-0 cursor-pointer" /* onClick={() => navigate('/testseries-subscribers', { state: { courseId: item.courseId } })} */>
                                                <Badge bg="" className='badge-primary light badge-sm ms-2 cursor-pointer pe-3 ps-3' >    {item.subscribers ?? 0}</Badge>
                                            </h6>
                                        </td>

                                    </tr>
                                )) :
                                    <tr>
                                        <td colSpan="9" className="text-center">
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                                {
                                                    showTableResponse(tests, checked)
                                                }
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div >
            <BooksModal ref={childRef} loadCourses={loadCourses} setCourseLoading={setCourseLoading} />
        </Fragment >
    );
};

export default Books;