import React, { Fragment, useState, useRef, useEffect } from "react";
import parse from 'html-react-parser';
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Dropdown, Tab, Nav, Button, ButtonGroup, Toast, SplitButton, Modal } from "react-bootstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../../http/http-config";
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonMUI from '@mui/material/Button';
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";
import { TiTick } from "react-icons/ti";
import '../../QuestionBank/QuestionBank.css'
import swal from "sweetalert";
import QuestionSampleFile from '../../../../download_files/Question_Sample_Files.docx'

import { fetchSubTestData, formateSubTestData } from "../../TestManagement/TestHelper";
import { getTestQuestions, setQuestionBoxStyling } from "../../QuestionBank/questionBankService";
import TopicQuestionModal from "./TopicQuestionModal";
import { ButtonBase, Stack } from "@mui/material";
import { deleteItem } from "../../common-helpers/commonServices";
import { getTopic, getTopicQuestions } from "./TopicHelper";
const TopicQuestionBank = () => {
    const navigate = useNavigate();
    const childRef = useRef();
    const { state } = useLocation();
    console.log('state', state)
    const topicId = state?.topicId;
    console.log(topicId)
    // const { testId } = useParams();

    const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(1);
    const [topicData, setTopicData] = useState({});
    console.log(topicData)
    const [questionCount, setQuestionCount] = useState(topicData?.questionCount);//for updating question count
    console.log(questionCount)

    const [topicQuestions, setTopicQuestions] = useState([]);
    const [existedQuestions, setExistedQuestions] = useState([]);
    console.log(existedQuestions)
    const [existedQuestionNumbers, setExistedQuestionNumbers] = useState([]);
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState({});
    const [showQuestion, setShowQuestion] = useState(false);
    const [loadQuestions, setLoadQuestions] = useState(false);
    const [loadTopicData, setLoadTopicData] = useState(false);
    //console.log(selectedQuestion, selectedQuestionNumber, showQuestion)
    const [modalBox, setModalBox] = useState(false)
    const [modalBox2, setModalBox2] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        getTopic(topicId).then(res => {
            if (res.title) {
                setTopicData(res)
                setQuestionCount(res.questionCount)
                let emptyArray = new Array(res.questionCount).fill({}).map((emptyObj, index) => ({ questionNumber: index + 1, isQuestionExisted: false }))
                setTopicQuestions(emptyArray)
            }
        })
    }, [loadTopicData])

    useEffect(() => {
        if (topicData.sn) {
            getTopicQuestions(topicId).then(res => {
                setExistedQuestions(res)
                let questionNumbers = res.map(question => question.questionNumber)
                setExistedQuestionNumbers([...new Set(questionNumbers)])
                setTopicQuestions(topicQuestions.map(question => {
                    return {
                        ...question,
                        isQuestionExisted: questionNumbers.includes(question.questionNumber) ? true : false
                    }
                }))
            }
            ).catch(error => {
                console.log(error)
            })
        }
    }, [topicData, loadQuestions])

    useEffect(() => {
        console.log('enter')
        let selectedQ = existedQuestions.find(question => question.questionNumber == selectedQuestionNumber)
        if (selectedQ?.questionNumber) {
            setSelectedQuestion(selectedQ)
            setCorrectAnswer(selectedQ.correctAnswer.toLowerCase())
            setShowQuestion(true)
        } else {
            setSelectedQuestion({})
            setShowQuestion(false)
        }

    }, [selectedQuestionNumber, existedQuestions])


    function handleUploadFile() {
        toast.info("Question paper file is being proccessed, It will take 1 minute to save quetions")
        setModalBox(false)
        let formData = new FormData();
        formData.append('uploadedFile', selectedFile, selectedFile.name);
        //  formData.append('totalQuestions', totalQuestoins);   
        console.log(formData)
        axios.post(BACKEND_SERVER_URL + `/uploadTopicQuestions/${topicId}`, formData)
            .then((response) => {
                if (response.data.statusCode == 200) {
                    toast.success(response.data.message);
                }
                setLoadQuestions(!loadQuestions)
            })
            .catch(error => {
                toast.error(error.response.data.message);
            });
    }

    function handleUpdateQuestionCount() {
        axios.put(BACKEND_SERVER_URL + `/updateQuestionCount/${topicId}/${questionCount}`)
            .then((response) => {
                if (response.data.statusCode == 200) {
                    toast.success(response.data.message);
                    setModalBox2(false)
                    setLoadTopicData(!loadTopicData)
                }
                setLoadQuestions(!loadQuestions)
            })
            .catch(error => {
                toast.error(error.response.data.message);
            });
    }


    return (
        <Fragment >
            <div className="card p-0 rounded-0 mb-0 ps-0">
                <div className="row gx-0 ">
                    <div className=" bg-light " style={{ flex: 2 }}>
                        <div style={{ backgroundColor: "#f4f5f6" }}>
                            <ButtonMUI disabled={!topicData.courseId} className="ms-2 mt-2 mb-2" variant="outlined" color="secondary" startIcon={<SlArrowLeft size={10} />} onClick={() => navigate('/lesson-details'/* , { state: { courseId: topicData?.courseId } } */)} >
                                Back
                            </ButtonMUI>
                        </div>
                        <button className=" w-75 ms-4 mt-2 btn btn-info btn-xs " onClick={() => setModalBox(true)}> Import Quetions</button>
                        <div className="email-left-box dlab-scroll pt-2" >
                            <div className="col-sm-12 mt-0">
                                <div className="row d-flex  justify-content-start ">
                                    {topicQuestions?.map((questionBoxData) => (
                                        <div className="col-1 m-1 ms-3 rounded-circle text-center cursor-pointer"
                                            style={setQuestionBoxStyling(questionBoxData, selectedQuestionNumber)}
                                            onClick={() => setSelectedQuestionNumber(questionBoxData.questionNumber)}
                                        >
                                            {questionBoxData.questionNumber}
                                        </div>
                                    ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="" style={{ flex: 10 }}>
                        <div className="email-right-box ">
                            {<Tab.Container defaultActiveKey="Import">
                                <div role="toolbar" className="toolbar ms-1 ms-sm-0 ">
                                    <div className="saprat " style={{ backgroundColor: "#f4f5f6" }}>
                                        <div className="col-2 m-auto  ms-0">
                                            <ButtonMUI variant="outlined" color="primary" endIcon={<FaEdit />} onClick={() => setModalBox2(true)}>
                                                Total Qs: {topicData.questionCount}
                                            </ButtonMUI>
                                        </div>
                                        <div className="col-2 m-auto  ms-0">
                                            <h4 className="ps-3"> Question: {selectedQuestionNumber}</h4>
                                        </div>
                                        <div className="col-8 m-auto ms-0 ">
                                            <h4 className=''>
                                                <span style={{ fontWeight: 'bold' }}>
                                                    LESSION NAME :{" "}
                                                </span>
                                                <span style={{ textDecoration: 'underline', color: 'green' }}>
                                                    {topicData?.title && topicData.title.length > 50 ? `${topicData.title.slice(0, 65)}...` : topicData.title}
                                                </span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-xxl-12 col-lg-12 ">
                                    <div className="card h-auto">
                                        <div className="card-footer p-2">
                                            <div className="row d-flex justify-content-between">
                                                <div className="d-flex justify-content-between">
                                                    <ButtonMUI variant="outlined" color="secondary" startIcon={<SlArrowLeft size={10} />} onClick={() => setSelectedQuestionNumber(selectedQuestionNumber - 1)}
                                                        disabled={selectedQuestionNumber == 1}>
                                                        Previous
                                                    </ButtonMUI>
                                                    <ButtonMUI variant="outlined" color="success" endIcon={<SlArrowRight size={10} />} onClick={() => setSelectedQuestionNumber(selectedQuestionNumber + 1)} disabled={selectedQuestionNumber == topicData?.questionCount}>
                                                        Next
                                                    </ButtonMUI>
                                                </div>
                                            </div>
                                        </div>
                                        {(showQuestion) ? <div className="card-body pt-0 ">
                                            <div className="question-bank-styling " style={{ color: 'black' }}>
                                                {/*  <p className="heading text-dark">Question: {" " + selectedQuestionNumber} </p> */}
                                                <div className="d-flex ps-2 ">
                                                    <div>  <span className={` ps-2 `}>{selectedQuestion.question ? parse(selectedQuestion.question) : null}</span> </div>
                                                </div>

                                                <div className={` align-items-baseline ${correctAnswer.toLowerCase() === 'a' ? 'd-inline-flex bg-success text-white pe-2' : 'd-flex'}`}>
                                                    <strong className="me-2">(A)</strong>
                                                    <span>{selectedQuestion.option1 ? parse(selectedQuestion.option1) : null}</span>
                                                </div>
                                                <div className={` align-items-baseline ${correctAnswer.toLowerCase() === 'b' ? 'd-inline-flex bg-success text-white pe-2' : 'd-flex'}`}>
                                                    <strong className="me-2">(B)</strong>
                                                    <span>{selectedQuestion.option2 ? parse(selectedQuestion.option2) : null}</span>
                                                </div>
                                                <div className={` align-items-baseline ${correctAnswer.toLowerCase() === 'c' ? 'd-inline-flex bg-success text-white pe-2' : 'd-flex'}`}>
                                                    <strong className="me-2">(C)</strong>
                                                    <span>{selectedQuestion.option3 ? parse(selectedQuestion.option3) : null}</span>
                                                </div>
                                                <div className={` align-items-baseline ${correctAnswer.toLowerCase() === 'd' ? 'd-inline-flex bg-success text-white pe-2' : 'd-flex'}`}>
                                                    <strong className="me-2">(D)</strong>
                                                    <span>{selectedQuestion.option4 ? parse(selectedQuestion.option4) : null}</span>
                                                </div>


                                                {/* <div className="d-flex align-items-baseline" style={{ display: 'flex' }}>
                                                    <strong className={`${correctAnswer == 'b' && 'bg-success text-white'} `}>(B)</strong>
                                                    <span className={` ps-2  ${correctAnswer == 'b' && 'bg-success text-white'}`}>{selectedQuestion.option2 ? parse(selectedQuestion.option2) : null}</span>
                                                </div> */}
                                                {/* <div className="d-flex  align-items-baseline" style={{ display: 'flex' }}>
                                                    <strong className={`${correctAnswer == 'c' && 'bg-success text-white'} `}>(C)</strong>
                                                    <span className={` ps-2 ${correctAnswer == 'c' && 'bg-success text-white'}`}>{selectedQuestion.option3 ? parse(selectedQuestion.option3) : null}</span>
                                                </div>
                                                <div className="d-flex align-items-baseline" style={{ display: 'flex' }}>
                                                    <strong className={`${correctAnswer == 'd' && 'bg-success text-white'} `}>(D)</strong>
                                                    <span className={` ps-2 ${correctAnswer == 'd' && 'bg-success text-white'}`}>{selectedQuestion.option4 ? parse(selectedQuestion.option4) : null}</span>
                                                </div> */}

                                                <div className="d-flex" style={{ display: 'flex' }}>
                                                    <strong >Explanation: </strong>
                                                    <span className={` ps-2 `}>{selectedQuestion?.description ? parse(selectedQuestion?.description) : null}</span>
                                                </div>

                                            </div>
                                        </div> :
                                            <div className="card-body m-auto">
                                                <button type="button" className="btn btn-dark btn-sm"
                                                    onClick={() => childRef.current.openModal(topicId, topicData.sectionId, selectedQuestionNumber, {})}
                                                >
                                                    + Add Question
                                                </button>
                                            </div>

                                        }
                                        {showQuestion && <div className="card-footer">
                                            <div className="row d-flex justify-content-between">
                                                <div className="col-md-3 d-flex justify-content-between">
                                                    <Stack direction="row" spacing={2}>
                                                        <ButtonMUI variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => { deleteItem(selectedQuestion.sn, 'Question', '/hideTopicQuestion/', setLoadQuestions, loadQuestions) }}>
                                                            Delete
                                                        </ButtonMUI>
                                                        <ButtonMUI variant="outlined" color="primary" endIcon={<FaEdit />} onClick={() => childRef.current.openModal(topicId, topicData.sectionId, selectedQuestionNumber, selectedQuestion)}>
                                                            Edit
                                                        </ButtonMUI>
                                                    </Stack>
                                                </div>
                                                <div className="col-md-3 d-flex justify-content-between">
                                                    <Stack direction="row" spacing={2}>
                                                        <ButtonMUI variant="outlined" color="secondary" startIcon={<SlArrowLeft size={10} />} onClick={() => setSelectedQuestionNumber(selectedQuestionNumber - 1)}
                                                            disabled={selectedQuestionNumber == 1}>
                                                            Previous
                                                        </ButtonMUI>
                                                        <ButtonMUI variant="outlined" color="success" endIcon={<SlArrowRight size={10} />} onClick={() => setSelectedQuestionNumber(selectedQuestionNumber + 1)} disabled={selectedQuestionNumber == topicData?.questionCount}>
                                                            Next
                                                        </ButtonMUI>
                                                    </Stack>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>

                                </div>
                            </Tab.Container>
                            }
                        </div>
                    </div>

                </div>
            </div>
            <Modal onHide={setModalBox} show={modalBox} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Import Question file</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3 d-block">
                            <a href={QuestionSampleFile} download="question_sample_file.docx" ><Button variant="outline-info" size='sm' title='Click here to download the sample file'>Download Sample file </Button> </a>
                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor="basic-url" className="form-label d-block">Upload file</label>
                            <input type="file" className="form-control w-100" onChange={(e) => setSelectedFile(e.target.files[0])} accept=".docx" placeholder="upload file" />
                            {selectedFile == null ? < p className='text-danger'>Please select file</p> : null}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs" onClick={handleUploadFile}>Upload file</button>
                    </div>
                </div>
            </Modal >
            <Modal onHide={setModalBox2} show={modalBox2} centered>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Change Question Count</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox2(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3 d-block">
                            <label htmlFor="basic-url" className="form-label d-block">Enter Number</label>
                            <input type="number" className="form-control w-100" value={questionCount} onChange={(e) => setQuestionCount(e.target.value)} placeholder="Entder Number...." />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox2(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs" onClick={handleUpdateQuestionCount}>Update</button>
                    </div>
                </div>
            </Modal >
            <TopicQuestionModal ref={childRef} setLoadQuestions={setLoadQuestions} loadQuestions={loadQuestions} />
        </Fragment >

    );
};

export default TopicQuestionBank;

