import React, { Fragment, useState, useRef, useEffect } from "react";
import parse from 'html-react-parser';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dropdown, Tab, Nav, Button, ButtonGroup, Toast, SplitButton, Modal, Spinner } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import { CKEditor } from "@ckeditor/ckeditor5-react";

import axios from "axios";
import { BACKEND_SERVER_URL } from "../../../http/http-config";
import { toast } from 'react-toastify';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonMUI from '@mui/material/Button';
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";
import { TiTick } from "react-icons/ti";
import '../QuestionBank/QuestionBank.css'
import swal from "sweetalert";
import QuestionSampleFile from '../../../download_files/Question_Sample_Files.docx'

import { fetchSubTestData, formateSubTestData } from "../TestManagement/TestHelper";
import { setQuizQuestionBoxStyling } from "../QuestionBank/questionBankService";
import QuestionModal from "../QuestionBank/QuestionModal";
import { ButtonBase, Stack } from "@mui/material";
import { deleteItem, getQuizQuestions } from "../common-helpers/commonServices";
import QuizQuestionBankModal from "./QuizQuestionBankModal";
import SubjectAndSectionDropdown from "./SubjectAndSectionDropdown";
const QuizQuestionBank = () => {
    const childRef = useRef();
    const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(1);
    const [quizQuestions, setQuizQuestions] = useState([]);
    const [existedQuestionNumbers, setExistedQuestionNumbers] = useState([]);
    const [correctAnswer, setCorrectAnswer] = useState('');
    const [selectedQuestion, setSelectedQuestion] = useState({});
    const [showQuestion, setShowQuestion] = useState(false);
    const [loadQuestions, setLoadQuestions] = useState(false);
    const [loadingQuestions, setLoadingQuestions] = useState(false);
    //console.log(selectedQuestion, selectedQuestionNumber, showQuestion)
    const [modalBox, setModalBox] = useState(false)
    const [selectedFile, setSelectedFile] = useState(null);
    const [questionOffset, setQuestionOffset] = useState(1);

    console.log(selectedQuestion)
    useEffect(() => {
        setLoadingQuestions(true);
        getQuizQuestions(questionOffset).then(res => {
            setQuizQuestions((preValue) => preValue.concat(res));
            setLoadingQuestions(false);
        }
        ).catch(error => {
            setLoadingQuestions(false);
            console.log(error)
            toast.error(error.response.data.message)
        })

    }, [loadQuestions])

    useEffect(() => {

        let selectedQ = quizQuestions.find(question => question.questionNumber == selectedQuestionNumber)
        if (selectedQ?.questionNumber) {
            setSelectedQuestion(selectedQ)
            setCorrectAnswer(selectedQ.correctAnswer.toLowerCase())
            setShowQuestion(true)
        } else {
            setSelectedQuestion({})
            setShowQuestion(false)
        }

    }, [selectedQuestionNumber, quizQuestions])

    const [ids, setIds] = useState({ subjectId: 0, sectionId: 0 })

    function handleUploadFile() {
        if (!ids.subjectId || !ids.sectionId) {
            toast.error("Please Select Subject and Section");
            return;
        }
        toast.info("Question paper file is being proccessed, It will take 1 minute to save quetions")
        setModalBox(false)
        let formData = new FormData();
        formData.append('uploadedFile', selectedFile, selectedFile.name);
        //  formData.append('totalQuestions', totalQuestoins);   
        console.log(formData)
        axios.post(BACKEND_SERVER_URL + `/uploadQuizQuestions/${ids.subjectId}/${ids.sectionId}`, formData)
            .then((response) => {
                if (response.data.statusCode == 200) {
                    toast.success(response.data.message);
                    setIds({ subjectId: 0, sectionId: 0 })
                }
                setLoadQuestions(!loadQuestions)
            })
            .catch(error => {
                setIds({ subjectId: 0, sectionId: 0 })
                toast.error(error.response.data.message);
            });
    }
    const loadMoreQuestions = () => {
        setQuestionOffset((previous) => (previous + 100));
        setLoadQuestions(!loadQuestions);
    }

    return (
        <Fragment >
            <div className="card p-0 rounded-0 mb-0 ps-0">
                <div className="row gx-0 ">
                    <div className=" bg-light " style={{ flex: 3 }}>
                        {/* <div style={{ backgroundColor: "#f4f5f6" }}>
                            <ButtonMUI  className="ms-2 mt-2 mb-2" variant="outlined" color="secondary" startIcon={<SlArrowLeft size={10} />} onClick={() => navigate('/test-')} >
                                Back
                            </ButtonMUI>
                        </div> */}
                        <div className="d-flex justify-content-between mt-1 p-1">
                            <button className=" btn btn-info btn-xs " onClick={() => setModalBox(true)}> Import </button>
                            <button type="button" className="btn btn-dark btn-xs"
                                onClick={() => childRef.current.openModal({})}
                            >
                                + Add
                            </button>
                        </div>
                        <div className="email-left-box dlab-scroll pt-2 px-1" >
                            <div className="col-sm-12 mt-0 ms-2">
                                <div className="d-flex justify-content-start flex-wrap">
                                    {quizQuestions?.map((questionBoxData, index) => (
                                        <div
                                            // style={setQuizQuestionBoxStyling()}
                                            key={questionBoxData.questionNumber} // Add key here for optimization
                                            className={` d-inline-flex justify-content-center col-2 cursor-pointer border-2 rounded-circle m-2   ${questionBoxData.questionNumber === selectedQuestionNumber ? "bg-dark text-white" : ""}`}
                                            onClick={() => setSelectedQuestionNumber(questionBoxData.questionNumber)}
                                        >
                                            <span className="px-1 py-2 fw-bold ">  {questionBoxData.questionNumber}</span>

                                        </div>
                                    ))}
                                </div>
                            </div>
                            <button
                                className="btn btn-secondary btn-sm w-50 mt-3"
                                onClick={loadMoreQuestions} // Assuming this function is triggered when the button is clicked
                            >
                                {loadingQuestions ? <Spinner size="sm" animation="border" variant="" /> : <span>{"Load more..."}</span>}
                            </button>



                        </div>
                    </div>
                    <div className="" style={{ flex: 10 }}>
                        <div className="email-right-box ">
                            {<Tab.Container defaultActiveKey="Import">
                                <div role="toolbar" className="toolbar ms-1 ms-sm-0 ">
                                    <div className="saprat d-flex justify-content-start align-items-center  " style={{ backgroundColor: "#f4f5f6" }}>
                                        <div className="  m-auto  ">
                                            <h4 className="ps-3"> Q. No: {selectedQuestionNumber}</h4>

                                        </div>
                                        <div className="  m-auto  ">
                                            <h4 className="ps-3"> SUBJECT: {selectedQuestion?.subject}</h4>

                                        </div>
                                        <div className="  m-auto  ">
                                            <h4 className="ps-3"> SECTION: {selectedQuestion?.section}</h4>

                                        </div>
                                        <div className="  m-auto  ">
                                            <h4 className="ps-3"> TOTAL QUESTIONS: {quizQuestions?.length}</h4>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-12 col-xxl-12 col-lg-12 ">
                                    <div className="card h-auto">
                                        <div className="card-footer p-2">
                                            <div className="row d-flex justify-content-between">
                                                <div className="d-flex justify-content-between">
                                                    <ButtonMUI variant="outlined" color="secondary" startIcon={<SlArrowLeft size={10} />} onClick={() => setSelectedQuestionNumber(selectedQuestionNumber - 1)}
                                                        disabled={selectedQuestionNumber == 1}>
                                                        Previous
                                                    </ButtonMUI>
                                                    <ButtonMUI variant="outlined" color="success" endIcon={<SlArrowRight size={10} />} onClick={() => setSelectedQuestionNumber(selectedQuestionNumber + 1)} disabled={selectedQuestionNumber == quizQuestions?.length}>
                                                        Next
                                                    </ButtonMUI>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body pt-0 ">
                                            <div className="question-bank-styling " style={{ color: 'black' }}>
                                                {/*  <p className="heading text-dark">Question: {" " + selectedQuestionNumber} </p> */}
                                                <div className="d-flex ps-2 ">
                                                    <div>  <span className={` ps-2 `}>{selectedQuestion.question ? parse(selectedQuestion.question) : null}</span> </div>
                                                </div>

                                                <div className="d-flex " style={{ display: 'flex' }}>
                                                    <strong className={`${correctAnswer == 'a' && 'bg-success text-white'}`}> (A)</strong>
                                                    <span className={` ps-2 ${correctAnswer == 'a' && 'bg-success text-white'}`}> {selectedQuestion.option1 ? parse(selectedQuestion.option1) : null} </span>
                                                </div>
                                                <div className="d-flex " style={{ display: 'flex' }}>
                                                    <strong className={`${correctAnswer == 'b' && 'bg-success text-white'} `}>(B)</strong>
                                                    <span className={` ps-2 ${correctAnswer == 'b' && 'bg-success text-white'}`}>{selectedQuestion.option2 ? parse(selectedQuestion.option2) : null}</span>
                                                </div>
                                                <div className="d-flex " style={{ display: 'flex' }}>
                                                    <strong className={`${correctAnswer == 'c' && 'bg-success text-white'} `}>(C)</strong>
                                                    <span className={` ps-2 ${correctAnswer == 'c' && 'bg-success text-white'}`}>{selectedQuestion.option3 ? parse(selectedQuestion.option3) : null}</span>
                                                </div>
                                                <div className="d-flex" style={{ display: 'flex' }}>
                                                    <strong className={`${correctAnswer == 'd' && 'bg-success text-white'} `}>(D)</strong>
                                                    <span className={` ps-2 ${correctAnswer == 'd' && 'bg-success text-white'}`}>{selectedQuestion.option4 ? parse(selectedQuestion.option4) : null}</span>
                                                </div>

                                                <div className="d-flex" style={{ display: 'flex' }}>
                                                    <strong >Explanation: </strong>
                                                    <span className={` ps-2 `}>{selectedQuestion.description ? parse(selectedQuestion.option4) : null}</span>
                                                </div>

                                            </div>
                                        </div>
                                        {showQuestion && <div className="card-footer">
                                            <div className="row d-flex justify-content-between">
                                                <div className="col-md-3 d-flex justify-content-between">
                                                    <Stack direction="row" spacing={2}>
                                                        <ButtonMUI variant="outlined" color="error" startIcon={<DeleteIcon />} onClick={() => { deleteItem(selectedQuestion.sn, 'Question', '/deleteQuizQuestion/', setLoadQuestions, loadQuestions) }}>
                                                            Delete
                                                        </ButtonMUI>
                                                        <ButtonMUI variant="outlined" color="primary" endIcon={<FaEdit />} onClick={() => childRef.current.openModal(selectedQuestion)}>
                                                            Edit
                                                        </ButtonMUI>
                                                    </Stack>
                                                </div>
                                                <div className="col-md-3 d-flex justify-content-between">
                                                    <Stack direction="row" spacing={2}>
                                                        <ButtonMUI variant="outlined" color="secondary" startIcon={<SlArrowLeft size={10} />} onClick={() => setSelectedQuestionNumber(selectedQuestionNumber - 1)}
                                                            disabled={selectedQuestionNumber == 1}>
                                                            Previous
                                                        </ButtonMUI>
                                                        <ButtonMUI variant="outlined" color="success" endIcon={<SlArrowRight size={10} />} onClick={() => setSelectedQuestionNumber(selectedQuestionNumber + 1)} disabled={selectedQuestionNumber == quizQuestions?.length}>
                                                            Next
                                                        </ButtonMUI>
                                                    </Stack>
                                                </div>
                                            </div>
                                        </div>
                                        }
                                    </div>

                                </div>
                            </Tab.Container>
                            }
                        </div>
                    </div>

                </div>
            </div>
            <Modal onHide={setModalBox} show={modalBox} centered backdrop="static">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Import Question file</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3 d-block">
                            <a href={QuestionSampleFile} download="question_sample_file.docx" ><Button variant="outline-info" size='sm' title='Click here to download the sample file'>Download Sample file </Button> </a>
                        </div>
                        <div className="mb-3 d-flex justify-content-around">
                            <SubjectAndSectionDropdown setFormData={setIds} formData={ids} subjectId={ids.subjectId} sectionId={ids.sectionId} />
                        </div>
                        <div className="mb-3 d-block">
                            <label htmlFor="basic-url" className="form-label d-block">Upload file</label>
                            <input type="file" className="form-control w-100" onChange={(e) => setSelectedFile(e.target.files[0])} accept=".docx" placeholder="upload file" />
                            {selectedFile == null ? < p className='text-danger'>Please select file</p> : null}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs" onClick={handleUploadFile}>Upload file</button>
                    </div>
                </div>
            </Modal >

            <QuizQuestionBankModal ref={childRef} setLoadQuestions={setLoadQuestions} loadQuestions={loadQuestions} />
        </Fragment >

    );
};

export default QuizQuestionBank;

