import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'

import { BACKEND_SERVER_URL } from '../../../http/http-config';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import Input from '../Common-Components/Input';
import FileInput from '../Common-Components/FileInput';
import DropdownInput from '../Common-Components/DropdownInput';
import BatchInput from '../Course/BatchInput';
import { fetchCourseData, formateCourseData, generateUniqueID, getExamTypes, getFaculties, getTypes, validateCourseData } from '../Course/CourseHelper';
import DateInput from '../Common-Components/DateInput';
import '../Course/Course.css'
import CKEditorInput from '../Common-Components/CKEditorInput';

import { setDropDownStyle } from '../CommonHelper';

let initialFormData = {
    sNo: 0,
    title: '',
    accessCode: 1,
    description: '',
    topic: '',
    questionCount: 100,
    attempts: 1,
    totalMarks: 0,
    positiveMarks: 0,
    negativeMarks: 0,
    duration: 60,
    publishDate: new Date(),
    endDate: new Date(),
    availability: '',
    type: 'MCQ',
    demo: 'NO',
    holdResult: 'NO',
    tags: '',
    notesPDF: '',
    questionPDF: '',
    answerBookPDF: '',
    modelAnswerPDF: '',
    videoURL: '',
    packageId: '',
    courseId: '',
    initialRank: 0
}

const BookTestModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [testId, setTestId] = useState('');
    const [updatingTestId, setUpdatingTestId] = useState(0);
    const [examTypesArray, setExamTypesArray] = useState([]);
    const [typesArray, setTypesArray] = useState([]);
    const [facultyArray, setFaculties] = useState([])
    const [formData, setFormData] = useState(initialFormData);
    const [updatingDataReady, setUpdatingDataReady] = useState(false);
    console.log(formData)
    useImperativeHandle(ref, () => ({
        openModal(courseId, packageId) {
            setModalBox(true);
            handleChange('courseId', courseId);
            if (packageId) {
                setUpdatingTestId(packageId)
                axios.get(BACKEND_SERVER_URL + '/getBookTestData/' + packageId).then((res) => {
                    let test = res.data.test;
                    setFormData((prevData) => ({ ...prevData, ...test }))
                }).catch(error => {
                    toast.error(error.response.data.message)
                })

            } else {
                setFormData(initialFormData)
                let uniqueId = generateUniqueID();
                handleChange('packageId', uniqueId)//property name and value
                handleChange('courseId', courseId);
                // setModalBox(true);
            }
        }
    }));

    const handleChange = (name, value) => {
        setFormData((previousData) => ({
            ...previousData,
            [name]: value
        }));
    };
    console.log(updatingTestId)
    const handleSubmitTest = () => {
        // let errorMessgae = validateSubTestData(formData)
        //  console.log(errorMessgae)
        //  if (errorMessgae) {
        //      toast.error(errorMessgae)
        //      return;
        //  }

        axios.post(BACKEND_SERVER_URL + (updatingTestId ? `/editBookTest/${updatingTestId}` : '/createBookTest'), formData).then((response) => {
            if (response.data.statusCode == 200) {
                console.log("updated")
                setFormData(initialFormData)
                toast.success(response.data.message)
                props.setSubTestLoading(!props.loadSubTests)
                setModalBox(false);
            }
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message)
        })

    }
    useEffect(() => {
        if (!modalBox) {
            setUpdatingTestId(0);
            setFormData(initialFormData); // Reset form data when modal is closed
        }
    }, [modalBox]);

    return (
        <>
            <Modal className="" show={modalBox} onHide={setModalBox} dialogClassName="custom-modal-width" backdrop="static"
                keyboard={false} >
                <Modal.Header>
                    <Modal.Title>{updatingTestId ? 'Update Test' : 'Create Test'}</Modal.Title>
                    <Button variant="" className="btn-close" onClick={() => setModalBox(false)}></Button>
                </Modal.Header>
                <Modal.Body>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input inputType="text" handleChangeCallBack={handleChange} labelName="Title" inputValue={formData?.title} inputName='title' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="S. No." inputType="number" inputValue={formData?.sNo} inputName='sNo' divSizes={[4, 8]} />
                                </div>
                                {/*   <div className="col-5 d-flex">
                                    <div className="col-sm-4 pt-2">
                                        <div className="mb-3" >
                                            <label htmlFor='news' className="form-label text-primary">Exam Type<span className="required">*</span></label>
                                        </div>
                                    </div>
                                    <div className="col-sm-8">
                                        <div className="mb-3">
                                            <Select
                                                value={{ value: formData.examType, label: formData.examType }}
                                                onChange={(selectedOption) => setFormData({ ...formData, examTypeId: selectedOption.value.Sn, examType: selectedOption.value.exambody })}
                                                options={examTypesArray?.map((item) => ({ value: item, label: item.exambody }))}
                                                styles={setDropDownStyle()}
                                            />
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                            <div className="col-12 d-flex justify-content-around ">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Access Code" inputType="number" inputValue={formData?.accessCode} inputName='accessCode' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="Package ID" inputType="text" inputValue={formData?.packageId} inputName='packageId' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Question Count" inputType="number" inputValue={formData?.questionCount} inputName='questionCount' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Attempt" inputType="number" inputValue={formData?.attempts} inputName='attempts' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Duration (in minutes)" inputType="number" inputValue={formData?.duration} inputName='duration' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Tags" inputType="text" inputValue={formData?.tags} inputName='tags' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Total Marks" inputType="number" inputValue={formData?.totalMarks} inputName='totalMarks' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Positive Marks" inputType="number" inputValue={formData?.positiveMarks} inputName='positiveMarks' divSizes={[4, 8]} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex">
                                    <Input handleChangeCallBack={handleChange} labelName="Negative Marks" inputType="number" inputValue={formData?.negativeMarks} inputName='negativeMarks' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Hold Result" selectedValue={formData?.holdResult} inputName='holdResult' dropdownValuesArray={['YES', 'NO']} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Demo" selectedValue={formData?.demo} inputName='demo' dropdownValuesArray={['YES', 'NO']} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Availibility" selectedValue={formData?.availability} inputName='availability' dropdownValuesArray={['Open to All', 'Close']} />
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <DropdownInput handleChangeCallBack={handleChange} labelName="Type" selectedValue={formData?.type} inputName='type' dropdownValuesArray={['MCQ', 'Subjective']} />
                                </div>
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="Video URL" inputType="text" inputValue={formData?.videoURL} inputName='videoURL' divSizes={[4, 8]} />

                                </div>
                            </div>

                            <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex align-items-center">
                                    <DateInput labelName="Publish Date" selected={formData?.publishDate} handleChangeCallBack={handleChange} inputName='publishDate' />
                                </div>
                                <div className="col-5 d-flex align-items-center">
                                    <DateInput labelName="End Date" selected={formData?.endDate} handleChangeCallBack={handleChange} inputName='endDate' />
                                </div>
                            </div>
                            {/* <div className="col-12 d-flex justify-content-around  ">
                                <div className="col-5 d-flex ">
                                    <Input handleChangeCallBack={handleChange} labelName="Initial Rank" inputType="number" inputValue={formData?.initialRank} inputName='initialRank' divSizes={[4, 8]} />
                                </div>
                                <div className="col-5 d-flex ">

                                </div>
                            </div> */}

                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Description" inputValue={formData?.description} inputName='description' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <CKEditorInput handleChangeCallBack={handleChange} labelName="Topic" inputValue={formData?.topic} inputName='topic' />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Notes PDF" inputType="text" uploadedFileURL={formData?.notesPDF} inputName='notesPDF' accept=".pdf" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Question PDF" inputType="text" uploadedFileURL={formData?.questionPDF} inputName='questionPDF' accept=".pdf" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Answer Book PDF" inputType="text" uploadedFileURL={formData?.answerBookPDF} inputName='answerBookPDF' accept=".pdf" />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="d-flex">
                                    <FileInput handleChangeCallBack={handleChange} labelName="Model Answer PDF" inputType="text" uploadedFileURL={formData?.modelAnswerPDF} inputName='modelAnswerPDF' accept=".pdf" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger light" className='btn-sm' onClick={() => setModalBox(false)}>Close</Button>
                    <Button variant="primary " className='btn-sm ' onClick={handleSubmitTest}>{updatingTestId ? 'Update Test' : 'Create Test'}</Button>
                </Modal.Footer>
            </Modal>


        </>
    )
})
export default BookTestModal;