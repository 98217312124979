import React, { Fragment, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Modal, Table } from 'react-bootstrap';
import { ListItem } from '@mui/material';
import Select from 'react-select';
import '../common-css/Table.css'
import { setDropDownStyle } from '../CommonHelper';
import { getQuizSubjectsForDropdowns } from '../common-helpers/commonServices';

const QuizSectionsModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [subjects, setSubjects] = useState([]);
    const [selectedSubjectId, setSubjectId] = useState();
    // console.log(selectedCourseId)

    const [sectionData, setSectionData] = useState([])
    console.log(sectionData)

    const [duplicateItems, setDuplicateItems] = useState(false)
    // console.log(duplicateItems)

    const [updatingSectionData, setUpdatingSectionData] = useState({ sn: 0, sectionName: '',titleURL:'', sectionOrderNo: 0, })
    //  console.log(courses)
    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            // console.log(item)
            if (item.sn > 0) {
                setUpdatingSectionData({
                    sn: item.sn,
                    sectionName: item.section,
                    titleURL: item.titleURL,
                    sectionOrderNo: item.sort,
                })

            } else {
                getQuizSubjectsForDropdowns().then(res => {
                    setSubjects(res)
                })
            }
            setModalBox(true)
        }
    }));

    const handleSaveSections = async () => {
        if (!updatingSectionData.sn && !sectionData.length) {
            toast.error("Please Select Subject Number")
            return;
        } else if (!updatingSectionData.sn && !selectedSubjectId) {
            toast.error("Please Select any Subject")
            return;
        } else if (sectionData.find(obj => !obj.sectionName)) {
            toast.error("Please Enter Section Name for all Sections")
            return;
        } else if (sectionData.find(obj => !obj.titleURL)) {
            toast.error("Please Enter Section Title URL for all Sections")
            return;
        }

        try {
            const res = await axios.post(BACKEND_SERVER_URL + (updatingSectionData?.sn ? `/updateQuizSection` : '/saveQuizSections'), updatingSectionData?.sn ? updatingSectionData : { subjectData: sectionData, selectedSubjectId: selectedSubjectId });
            if (res?.data.statusCode == 201) {
                toast.success(res.data.message);
                props.setSectionLoading(!props.loadSections);
                setUpdatingSectionData({})
                setModalBox(false);
            }
        } catch (error) {
            toast.error(error.response.data.message)
            console.error("An error occurred during the request:", error);

        }
    }




    useEffect(() => {
        if (!modalBox) {
            setSectionData([]); // Reset form data when modal is closed
            setUpdatingSectionData({})
            setDuplicateItems(false)
        }
    }, [modalBox]);

    const handleNumberOfSectionSelect = (number) => {
        let arr = new Array(number).fill({ sn: 1, sectionName: '',titleURL:'', sectionOrderNo: 0, })
        let newArr = arr.map((element, index) => {
            return {
                sn: index + 1, sectionName: '',titleURL:'', sectionOrderNo: 0,
            }
        })
        setSectionData(newArr)
    }
    const handleChange = (index, fieldName, value) => {
        let copy = [...sectionData]
        copy[index] = { ...copy[index], [fieldName]: value }
        setSectionData(copy)
    }
    return (
        <>
            {
                !updatingSectionData?.sn ? <Modal onHide={setModalBox} show={modalBox} dialogClassName="custom-modal-width" size="lg" backdrop="static"
                    keyboard={false} >
                    <Fragment>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="mb-0">{sectionData.sNo ? 'Update Sections' : 'Create Sections'}</h5>
                                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                                    </div>
                                    <div className="card-body pt-2 mt-0">
                                        <div className="row">
                                            <div className="col-10 m-auto d-flex  justify-content-around mb-3 ">
                                                <div className="col-4">
                                                    <label htmlFor="currentYear" className="form-label text-primary m-auto">No. of Sections<span className="required">*</span></label>
                                                    <div className="basic-dropdown">
                                                        <Select
                                                            //  isDisabled={batchData.sNo}
                                                            //  value={batchData.courseTitle ? { value: batchData.courseTitle, label: batchData.courseTitle } : null}
                                                            onChange={(selectedOption) => handleNumberOfSectionSelect(selectedOption?.value)}
                                                            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((number) => ({ value: number, label: number }))}
                                                            styles={setDropDownStyle()}

                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    {sectionData.length > 0 ? <>
                                                        <label htmlFor="currentYear" className="form-label text-primary m-auto">Subject<span className="required">*</span></label>
                                                        <div className="basic-dropdown">
                                                            <Select
                                                                //  isDisabled={batchData.sNo}
                                                                //  value={batchData.courseTitle ? { value: batchData.courseTitle, label: batchData.courseTitle } : null}
                                                                onChange={(selectedOption) => setSubjectId(selectedOption?.value.sn)}
                                                                options={subjects?.map((course) => ({ value: course, label: course.subject }))}
                                                                styles={setDropDownStyle()}
                                                            />
                                                        </div>
                                                    </> : null
                                                    }
                                                </div>
                                            </div>




                                            <div className="col-sm-10 m-auto ">
                                                <Table responsive bordered className="text-center mt-0 pt-0 ">
                                                    <thead>
                                                        <tr>
                                                            <th>SN.</th>
                                                            <th className='text-start'>SECTION NAME</th>
                                                            <th className='text-start'>TITLE URL</th>
                                                            <th>SECTION ORDER  NO.</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {sectionData?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td style={{ width: "100px" }}><h6 className="sn-font-weight mb-0" > {item.sn}</h6></td>
                                                                <td > <input className='form-control' value={sectionData[index].sectionName} type="text" name="" id="" onChange={(e) => handleChange(index, 'sectionName', e.target.value)} /></td>
                                                                <td > <input className='form-control' value={sectionData[index].titleURL} type="text" name="" id="" onChange={(e) => handleChange(index, 'titleURL', e.target.value)} /></td>
                                                                <td style={{ width: "100px" }}> <input className='form-control' value={sectionData[index].sectionOrderNo} type="number" name="" id="" onChange={(e) => handleChange(index, 'sectionOrderNo', e.target.value)} /></td>
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer pb-0">
                                        <button className='btn btn-danger  btn-xs ' onClick={() => setModalBox(false)}>Cancel</button>
                                        <button className='btn btn-primary  btn-xs ' onClick={handleSaveSections}>{sectionData.sNo ? 'Update Section' : 'Create Sections'}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Fragment >
                </Modal >
                    :
                    <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                        keyboard={false} >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel"> Update Section</h5>
                                <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                            </div>
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="sectionName" className="form-label text-primary">Section<span className="required">*</span></label>
                                    <input className='form-control' type="text" name="" id="sectionName" value={updatingSectionData.sectionName} onChange={(e) => setUpdatingSectionData({ ...updatingSectionData, sectionName: e.target.value })} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="titleURL" className="form-label text-primary">Section<span className="required">*</span></label>
                                    <input className='form-control' type="text" name="" id="titleURL" value={updatingSectionData.titleURL} onChange={(e) => setUpdatingSectionData({ ...updatingSectionData, titleURL: e.target.value })} />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="sort" className="form-label text-primary">Order No.<span className="required">*</span></label>
                                    <input className='form-control' type="number" name="" id="sort" value={updatingSectionData.sectionOrderNo} onChange={(e) => setUpdatingSectionData({ ...updatingSectionData, sectionOrderNo: e.target.value })} />
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                                <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveSections}>Update</button>
                            </div>
                        </div>
                    </Modal>
            }
        </>
    )
})
export default QuizSectionsModal;




























































































































































