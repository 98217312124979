import axios from "axios"
import { BACKEND_SERVER_URL } from "../../http/http-config"

import * as XLSX from 'xlsx';


export function setDropDownStyle() {
    return ({
        control: (provided) => ({
            ...provided,
            width: '100%',
            backgroundColor: 'transparent',
            borderColor: '#17a2b8'          
        })
    })
}
export async function getNewsCategories() {
    return (await axios.get(BACKEND_SERVER_URL + '/getNewsCategories')).data.newsCategories.
        filter(news => news.status == 1 && news.hide == 0)?.
        map(news => ({ title: news.category, sn: news.sn }))
}
export async function getNewsSubCategories() {
    return (await axios.get(BACKEND_SERVER_URL + '/getNewsSubCategories')).data.newsSubCategories.
        filter(news => news.status == 1 && news.hide == 0)?.
        map(news => ({ name: news.subCategory }))
}
export async function getBlogCategories() {
    return (await axios.get(BACKEND_SERVER_URL + '/getBlogCategories')).data.blogCategories.
        filter(news => news.status == 1 && news.hide == 0)?.
        map(news => ({ title: news.category, sn: news.sn }))
}

export async function getCourses() {
    return (await axios.get(BACKEND_SERVER_URL + '/getCoursesForTopic')).data.courses
}
export async function getBooksForDropdown() {
    return (await axios.get(BACKEND_SERVER_URL + '/getBooksForDropdown')).data.books
}
export async function getBookSubjectsForDropdown() {
    return (await axios.get(BACKEND_SERVER_URL + '/getBookSubjectsForDropdown')).data.subjects
}
export async function getTestSeries() {
    return (await axios.get(BACKEND_SERVER_URL + '/getTestSeriesForDropDowns')).data.testSeries
}
export async function getTestSeriesTests(courseId) {
    return (await axios.get(BACKEND_SERVER_URL + '/getSubTests/' + courseId)).data.tests
}
export async function getTestsForDropdown(courseId) {
    return (await axios.get(BACKEND_SERVER_URL + '/getTestsForDropdown/' + courseId)).data.tests
}
export async function getSubjects() {
    return (await axios.get(BACKEND_SERVER_URL + '/getSubjectsForTopic')).data.subjects
}
export async function getTopics() {
    return (await axios.get(BACKEND_SERVER_URL + '/getTopicsForDropDown')).data.topics
}
export async function getBatches() {
    return (await axios.get(BACKEND_SERVER_URL + '/getBatchesForDropdowns')).data.batches
}
export async function getTestSeriesBatches() {
    return (await axios.get(BACKEND_SERVER_URL + '/getTestSeriesBatches')).data.batches
}

export function validateQuizQuestionData(formData) {
    const requiredFields = ['question', 'option1', 'option2', 'option3', 'option4', 'description', 'correctAnswer', /* 'titleURL',  */ 'subjectId', 'sectionId'];

    for (const field of requiredFields) {
        if (!formData[field]) {
            return `${field.substring(0, 1).toUpperCase() + field.substring(1)} is Required`;
        }
    }

    return null; // Return null if all validations pass
}
export function handleOpenPDF(pdfUrl) {
    window.open(pdfUrl, '_blank');
};


export function exportToExcel(tableData, fileName = 'student_Data.xlsx') {
    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(tableData);

    // Calculate column widths
    const maxLengths = {};
    tableData.forEach(row => {
        Object.keys(row).forEach(key => {
            const value = row[key] ? row[key].toString() : '';
            maxLengths[key] = Math.max(maxLengths[key] || 0, value.length);
        });
    });

    const colWidths = Object.values(maxLengths).map(length => ({ wch: length + 1 }));

    // Assign column widths to worksheet
    worksheet['!cols'] = colWidths;

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Write the workbook and trigger the download
    XLSX.writeFile(workbook, fileName);
};
