import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { checkExistingItem } from '../common-helpers/commonServices';

import Select from 'react-select';
import { getBooksForDropdown, setDropDownStyle } from '../CommonHelper';



const BookSubjectModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    const [books, setBooks] = useState([]);

    let initialData = {
        sn: 0,
        sort: 0,
        title: '',
        course: 'Select Book',
        courseId: ''
    }
    const [courseTypeData, setCourseTypeData] = useState(initialData);
    console.log(courseTypeData)

    //check for existing items       
    const [itemAlreadyExist, setItemAlreadyExists] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('');//for updating 
    useEffect(() => {
        if (courseTypeData.title && currentTitle.toLowerCase().trim() != courseTypeData.title.toLowerCase().trim()) { // current title is for updating case
            console.log('enter')
            let timer = setTimeout(() => {
                checkExistingItem('in_coursetype', 'ctype', courseTypeData.title).then(response => { //tablename and columnNane value
                    setItemAlreadyExists(response)
                })

            }, 500);
            return () => clearTimeout(timer);
        }

    }, [courseTypeData.title]);







    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            getBooksForDropdown().then((res) => {
                setBooks(res)
            })
            if (item.sn > 0) {
                setCourseTypeData({ sn: item.sn, title: item.title, course: item.courseTitle, sort: item.orderNo, courseId: item.courseId })
                // setCurrentTitle(item.courseType)
            }

            setModalBox(true);
        }
    }));

    const handleSaveCourseType = (value) => {
        if (courseTypeData.title.trim().length === 0) {
            toast.error("Please Enter Subject")
        } else if (!courseTypeData.courseId) {
            toast.error("Please Select any course")
            return;
        }
        /* else if (itemAlreadyExist && currentTitle.toLowerCase().trim() != courseTypeData.subject.toLowerCase().trim()) {
           toast.error("Item Already Exists")
           return;
       } */
        axios.post(BACKEND_SERVER_URL + `${!courseTypeData.sn ? '/createBookSubject' : `/updateBookSubject`}`, courseTypeData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setSubjectLoading(!props.loadSubjects);
                setCourseTypeData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setCourseTypeData(initialData); // Reset form data when modal is closed
            setItemAlreadyExists(false)
            setCurrentTitle('')
        }
    }, [modalBox]);

    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {courseTypeData.sn ? "Update Subject" : "Add Subject"}</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body pt-3">
                        <div className="mb-3 ">
                            <label htmlFor="inputAcademicYear" className="form-label text-primary ">Course<span className="required">*</span></label>
                            <Select
                                value={{ value: courseTypeData.course, label: courseTypeData.course }}
                                onChange={(selectedOption) => setCourseTypeData((preData) => ({ ...preData, course: selectedOption.value.courseTitle, courseId: selectedOption.value.courseId }))}
                                options={books?.map((item) => ({ value: item, label: item.courseTitle }))}
                                styles={setDropDownStyle()}
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="inputAcademicYear" className="form-label text-primary">Subject<span className="required">*</span></label>
                            <input className='form-control' type="text" name="" id="inputAcademicYear" value={courseTypeData.title} onChange={(e) => setCourseTypeData({ ...courseTypeData, title: e.target.value })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sort" className="form-label text-primary">Order No.<span className="required">*</span></label>
                            <input className='form-control' type="number" name="" id="sort" value={courseTypeData.sort} onChange={(e) => setCourseTypeData({ ...courseTypeData, sort: +e.target.value })} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveCourseType}>{courseTypeData.sn ? "Update" : "Add"}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default BookSubjectModal;