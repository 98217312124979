import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify'
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { checkExistingItem } from '../common-helpers/commonServices';



const QuizSubjectModal = forwardRef((props, ref) => {
    const [modalBox, setModalBox] = useState(false);
    let initialData = {
        sn: 0,
        sort: 0,
        subject: '',
        titleURL:''
    }

    const [subjectData, setSubjectData] = useState(initialData);
    console.log(subjectData)

    //check for existing items       
    // const [itemAlreadyExist, setItemAlreadyExists] = useState(false);
    // const [currentTitle, setCurrentTitle] = useState('');//for updating 
    // useEffect(() => {
    //     if (subjectData.subject && currentTitle.toLowerCase().trim() != subjectData.subject.toLowerCase().trim()) {
    //         let timer = setTimeout(() => {
    //             let response = checkExistingItem('in_qsubject', 'Subject', subjectData.subject) //tablename and columnNane value
    //             setItemAlreadyExists(response)
    //         }, 500);
    //         return () => clearTimeout(timer);
    //     }
    // }, [subjectData.subject]);

    useImperativeHandle(ref, () => ({
        openModal(item = {}) {
            console.log(item)
            if (item.sn > 0) {
                setSubjectData({ sn: item.sn, subject: item.subject, titleURL: item.titleURL, sort: item.sort })
               // setCurrentTitle(item.facultyName)
            }
            setModalBox(true);
        }
    }));
 
    const handleSaveCourseType = (value) => {   
      
        if (subjectData.subject.trim().length === 0) {
            toast.error("Please Enter Subject")
        } /* else if (itemAlreadyExist && currentTitle.toLowerCase().trim() != subjectData.subject.toLowerCase().trim()) {
            toast.error("Subject Already Exists")
            return;
        } */
        axios.post(BACKEND_SERVER_URL + `${!subjectData.sn ? '/saveQuizSubject' : `/updateQuizSubject`}`, subjectData).then((response) => {
            if (response.data.statusCode === 201) {
                toast.success(response.data.message)
                props.setAcademicYearLoading(!props.loadAcademicYear);
                setSubjectData(initialData)
                setModalBox(false);
            }
        }).catch(error => {
            console.log(error)
            toast.error(error.response.data.message)
        })
    }
    useEffect(() => {
        if (!modalBox) {
            setSubjectData(initialData); // Reset form data when modal is closed
            //setItemAlreadyExists(false)
            //setCurrentTitle('')
        }
    }, [modalBox]);


    return (
        <>
            <Modal onHide={setModalBox} show={modalBox} size="md" backdrop="static"
                keyboard={false} >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel"> {subjectData.sn ? "Update Subject" : "Add Subject "}</h5>
                        <button type="button" className="btn-close" onClick={() => setModalBox(false)}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <label htmlFor="subjectName" className="form-label text-primary">Subject<span className="required">*</span></label>
                            <input className='form-control' type="text" name="" id="subjectName" value={subjectData.subject} onChange={(e) => setSubjectData({ ...subjectData, subject: e.target.value })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="titleUrl" className="form-label text-primary">Title URL<span className="required">*</span></label>
                            <input className='form-control' type="text" name="" id="titleUrl" value={subjectData.titleURL} onChange={(e) => setSubjectData({ ...subjectData, titleURL: e.target.value })} />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="sort" className="form-label text-primary">Priority<span className="required">*</span></label>
                            <input className='form-control' type="number" name="" id="sort" value={subjectData.sort} onChange={(e) => setSubjectData({ ...subjectData, sort: e.target.value })} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger btn-xs" onClick={() => setModalBox(false)}>Close</button>
                        <button type="button" className="btn btn-primary btn-xs " onClick={handleSaveCourseType}>{subjectData.sn ? "Update" : "Add "}</button>
                    </div>
                </div>
            </Modal>

        </>
    )
})
export default QuizSubjectModal;