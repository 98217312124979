import React, { useEffect, useRef, useState } from 'react'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import BookTestModal from './BookTestModal';
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";
import { format } from 'date-fns';

import swal from 'sweetalert';
import axios from 'axios';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { Accordion, Pagination } from '@mui/material';
import ButtonMUI from '@mui/material/Button';
import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, handleSearch, showTableResponse } from '../common-helpers/commonServices';
import ProdutsDetailsTabsAtTop from '../Common-Components/ProdutsDetailsTabsAtTop';
import SearchBox from '../Common-Components/SearchBox';
// import { fetchTestData, formateTestData } from '../TestHelper';
import { CiSettings } from "react-icons/ci";
// import '../test.css'


import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { SlArrowLeft } from 'react-icons/sl';



const PracticeTests = () => {
    const { state } = useLocation();
    const courseId = state?.courseId;
    const courseTitle = state?.courseTitle;
    // const testSeriesSn = state?.testSeriesSn;
    console.log(courseId)





    const childRef = useRef();
    const navigate = useNavigate();
    const [tests, setTests] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [checked, setChecked] = useState(tests);
    const [unchecked, setUnChecked] = useState(true);
    const [loadSubTests, setSubTestLoading] = useState(false);

    //test series data
    const [currentTestSeries, setCurrentTestSeries] = useState({})

    useEffect(() => {
        // fetchTestData(courseId).then(res => {
        //     formateTestData(res).then(formatedData => {
        //         setCurrentTestSeries(formatedData)
        //     })
        // })

        axios.get(BACKEND_SERVER_URL + `/getBookPracticeTests/${courseId}`).then((res) => {
            let tests = res.data.tests;  //recieving batch here   
            if (tests) {
                setTests(tests);
                setChecked(tests);
            }
        })
    }, [loadSubTests])

    const pageSize = 20;
    const lastIndex = currentPage * pageSize;
    const firstIndex = lastIndex - pageSize;
    const records = checked?.slice(firstIndex, lastIndex);
    const npage = Math.ceil(checked.length / pageSize)
    const number = [...Array(npage + 1).keys()].slice(1)

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : null);
    };
    const handleEditTest = (e, packageId) => {
        e.stopPropagation()
        childRef.current?.openModal(courseId, packageId)
    }
    const handleTestQuestions = (e, packageId, type) => {
        e.stopPropagation();
        if (type == 'MCQ') navigate(`/book-questions`, { state: { packageId: packageId, courseId, courseTitle/* , testSeriesSn */ } });
        else navigate(`/moderate-solution`, { state: { packageId: packageId, courseId: courseId } })

    }

    console.log(tests)

    return (
        <>
            <div className="row">
                <div className="col-xl-12">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
                                <div className="col-2">
                                    <button type="button" className=" btn btn-dark btn-sm "
                                        onClick={() => childRef.current.openModal(courseId)}
                                    >
                                        + Add
                                    </button>
                                </div>
                                <div className="col-10 ">
                                    <h4 className='text-start'>
                                        <span style={{ fontWeight: 'bold' }}>
                                            TEST SERIES NAME :{" "}
                                        </span>
                                        <span style={{ textDecoration: 'underline', color: 'green' }}>
                                            {courseTitle && courseTitle.toUpperCase()}
                                        </span>
                                    </h4>



                                </div>

                            </div>


                        </div>
                        <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
                            <div className="table-responsive full-data">
                                <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
                                    <div className="d-sm-flex text-center justify-content-between align-items-center">
                                        <div >
                                            <ButtonMUI className="ms-2 mt-2 mb-2" variant="outlined" color="secondary" startIcon={<SlArrowLeft size={10} />} onClick={() => navigate('/books')} >
                                                Back
                                            </ButtonMUI>
                                        </div>

                                        <div className='dataTables_info text-dark'>
                                            Records: {checked.length}
                                        </div>
                                        {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
                                            id="example-student_wrapper">
                                            <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
                                        </div> : null}
                                    </div>
                                    <div>
                                        {
                                            tests?.map(item => (
                                                <Accordion key={item.sn} expanded={expanded === item.sn} onChange={handleChange(item.sn)}>
                                                    <AccordionSummary
                                                        expandIcon={expanded ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                                                        aria-controls="panel1-content"
                                                        id="panel1-header"
                                                        style={{ backgroundColor: expanded ? '#e0e0e0' : '#f5f5f5 ' }}
                                                    >
                                                        <div style={{ display: 'flex', width: '100%' }} className='text-primary'>
                                                            <div style={{ flex: .6 }}>
                                                                <Typography style={{ color: '#303972', fontWeight: "bold" }}>{item.type === 'MCQ' ? 'PTS-' : 'MTS-'}{item.accessCode}</Typography>
                                                            </div>
                                                            <div style={{ flex: 3 }}>
                                                                <Typography style={{ color: '#303972', fontWeight: "bold" }}>{item.title} <span className='text-primary ms-2' style={{ color: '#303972', fontWeight: "250" }}>({item.packageId})</span></Typography>
                                                            </div>

                                                            {/*  <div style={{ flex: 1 }}>
                                <Typography style={{ color: '#303972' }}>YV MAY</Typography>
                              </div> */}
                                                            <div style={{ flex: 0.8 }}>
                                                                <Typography style={{ color: '#303972' }}>
                                                                    {
                                                                        item.type == 'MCQ' ? <ButtonMUI variant="outlined" size='small' style={{ color: 'black', borderColor: '#4CAF50' }} onClick={(e) => handleTestQuestions(e, item.packageId, item.type)} >Questions</ButtonMUI>
                                                                            :
                                                                            <ButtonMUI variant="outlined" size='small' style={{ color: 'black', borderColor: '#4CAF50' }} onClick={(e) => handleTestQuestions(e, item.packageId, item.type)} >Moderate</ButtonMUI>
                                                                    }
                                                                </Typography>
                                                            </div>
                                                            <div style={{ flex: 0.3 }}>
                                                                <Typography style={{ color: '#303972' }}>{<CiSettings size={25} color='#009688' onClick={(e) => handleEditTest(e, item.packageId)} />}</Typography>
                                                            </div>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails className='custom-accordion-details'>
                                                        <Typography variant="body1" component="div" style={{ color: "black" }} dangerouslySetInnerHTML={{ __html: item.description }} />
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BookTestModal ref={childRef} loadSubTests={loadSubTests} setSubTestLoading={setSubTestLoading} />
        </>
    )
}
export default PracticeTests;



































// import React, { useEffect, useRef, useState } from 'react'
// import { Link, useNavigate } from 'react-router-dom';
// import { Badge, Button, Spinner } from 'react-bootstrap';
// import BookTestModal from './BookTestModal';
// import { MdDeleteOutline } from "react-icons/md";
// import { CiEdit } from "react-icons/ci";
// import swal from 'sweetalert';
// import axios from 'axios';
// import { toast } from 'react-toastify';
// import { BACKEND_SERVER_URL } from '../../../http/http-config';
// import { Pagination } from '@mui/material';
// import '../common-css/Table.css'
// import { bulkOperations, deleteItem, handleChecked, handleCheckedAll, showTableResponse, sortInAlphabeticalOrder } from '../common-helpers/commonServices';
// import SearchBox from '../Common-Components/SearchBox';
// import ProdutsDetailsTabsAtTop from '../Common-Components/ProdutsDetailsTabsAtTop';
// import Select from 'react-select';
// import { getCourses } from '../CommonHelper';

// const PracticeTests = () => {
//     const navigate = useNavigate();
//     const childRef = useRef();
//     const [subjects, setSubjects] = useState([]);
//     const [courses, setCourses] = useState([]);
//     const [selectedCourse, setSelectedCourse] = useState({ courseTitle: "All Courses" });
//     const [sortingType, setSortingType] = useState('Show by Serial No.');
//     const [currentPage, setCurrentPage] = useState(1);
//     const [checked, setChecked] = useState(subjects);
//     const [unchecked, setUnChecked] = useState(true);
//     const [loadSubjects, setSubjectLoading] = useState(false);
//     const [totalActiveSubjects, setTotalActiveSubjects] = useState([]);
//     const [totalInactiveSubjects, setTotalInactiveSubjects] = useState([]);
//     const [totalArchivedSubjects, setTotalArchivedSubjects] = useState([]);
//     console.log(selectedCourse)

//     //set Table data
//     useEffect(() => {
//         axios.get(BACKEND_SERVER_URL + '/getBookPracticeTests').then((res) => {
//             let tests = res.data.tests;
//             console.log(tests)
//             if (tests) {
//                 setTotalActiveSubjects(tests.filter(batch => batch.status == 1 && batch.hide == 0))
//                 setTotalInactiveSubjects(tests.filter(batch => batch.status == 0 && batch.hide == 0))
//                 let archivedYears = tests.filter(batch => batch.hide == 1)
//                 setTotalArchivedSubjects(archivedYears)
//                 let activeInactiveSubjects = tests.filter(batch => batch.hide == 0);
//                 setSubjects(activeInactiveSubjects);
//                 setChecked(activeInactiveSubjects);

//             }
//         }).catch((error) => {
//             console.log(error)
//             toast.error(error.response.data.message)
//         })
//         getCourses().then((res) => {
//             res.unshift({ courseTitle: "All Courses" })
//             setCourses(res)
//         })
//     }, [loadSubjects])
//     const pageSize = 100;
//     const lastIndex = currentPage * pageSize;
//     const firstIndex = lastIndex - pageSize;
//     const records = checked?.slice(firstIndex, lastIndex);
//     const npage = Math.ceil(checked.length / pageSize)
//     const number = [...Array(npage + 1).keys()].slice(1)

//     const selectCourseHandler = (selectedCourse) => {
//         console.log(selectedCourse)
//         if (selectedCourse.courseTitle == 'All Courses') {
//             setChecked(subjects);
//             setSelectedCourse(selectedCourse);
//             setSortingType('Show by Serial No.')
//             return;
//         }
//         setSelectedCourse(selectedCourse);
//         let filteredSubjects = subjects.filter(subject => subject.courseId == selectedCourse.courseId || subject.courseTitle == selectedCourse.courseTitle)
//         setChecked(sortInAlphabeticalOrder(filteredSubjects, 'subject'))
//     }
//     const selectOrderHandler = (selectedOrderType) => {
//         let orderBySelectedSubjects = [...checked];
//         setSortingType(selectedOrderType)
//         if (selectedOrderType == 'Show by Serial No.') {
//             orderBySelectedSubjects.sort((a, b) => b.sn - a.sn);
//         } else {
//             orderBySelectedSubjects.sort((a, b) => b.subjectOrderNo - a.subjectOrderNo);
//         }
//         setChecked(orderBySelectedSubjects);
//     }


//     return (
//         <>
//             <div className="row">
//                 <div className="col-xl-12">
//                     <div className="row">
//                         <div className="col-xl-12">
//                             <div className=" page-title p-2 mb-2">{/*  flex-wrap  */}
//                                 <div className="row w-100 d-flex justify-content-between">
//                                     <div className=' col-sm-8 d-flex justify-content-between'>
//                                         <div className="col-2">
//                                             <button type="button" className=" btn btn-dark btn-sm "
//                                                 onClick={() => childRef.current.openModal()}
//                                             >
//                                                 + Add
//                                             </button>
//                                         </div>
//                                         {/* <div className="col-10 d-flex justify-content-evenly ">
//                                             <div className="col-5">
//                                                 <div className="basic-dropdown">
//                                                     <Select
//                                                         value={{ value: selectedCourse, label: selectedCourse.courseTitle }}
//                                                         onChange={(selectedOption) => selectCourseHandler(selectedOption.value)}
//                                                         options={courses?.map((course) => ({ value: course, label: course.courseTitle }))}
//                                                         styles={{
//                                                             control: (provided) => ({
//                                                                 ...provided,
//                                                                 width: '100%',
//                                                                 backgroundColor: 'transparent', // Adjust as needed
//                                                                 borderColor: '#17a2b8', // Adjust as needed

//                                                             }),
//                                                         }}
//                                                     />
//                                                 </div>
//                                             </div>
//                                             <div className="col-5">
//                                                 <div className="basic-dropdown">
//                                                     <Select
//                                                         isDisabled={selectedCourse.courseId ? false : true}
//                                                         value={{ value: sortingType, label: sortingType }}
//                                                         onChange={(selectedOption) => selectOrderHandler(selectedOption.value)}
//                                                         options={['Show by Serial No.', 'Show by Order No.']?.map((item) => ({ value: item, label: item }))}
//                                                         styles={{
//                                                             control: (provided) => ({
//                                                                 ...provided,
//                                                                 width: '100%',
//                                                                 backgroundColor: 'transparent', // Adjust as needed
//                                                                 borderColor: '#17a2b8', // Adjust as needed

//                                                             }),
//                                                         }}
//                                                     />
//                                                 </div>
//                                             </div>

//                                         </div> */}

//                                     </div>
//                                     <div className=" col-sm-4 input-group search-area mb-md-0 mb-3">
//                                         <SearchBox setChecked={setChecked} dataArray={subjects} searchingProperty={'title'} />
//                                     </div>
//                                 </div>

//                             </div>
//                             <ProdutsDetailsTabsAtTop setChecked={setChecked} totalItems={subjects} totalActiveItems={totalActiveSubjects} totalInactiveItems={totalInactiveSubjects} totalArchivedItems={totalArchivedSubjects} />
//                         </div>
//                         <div className="col-xl-12 wow fadeInUp" data-wow-delay="1.5s">
//                             <div className="d-sm-flex text-center justify-content-between align-items-center">
//                                 <div>
//                                     <Button variant="danger ms-3" size='xs' onClick={(e) => bulkOperations("delete", records, '/hideBookSubjects', 'Subjects', setSubjectLoading, loadSubjects)}>Delete</Button>
//                                     <Button variant="success ms-3 " size='xs' onClick={(e) => bulkOperations("activate", records, '/activateBookSubjects', 'Subjects', setSubjectLoading, loadSubjects)}>Active</Button>
//                                     <Button variant="warning ms-3" size='xs' onClick={(e) => bulkOperations("InActivate", records, '/inactivateBookSubjects', 'Subjects', setSubjectLoading, loadSubjects)}>Inactive</Button>
//                                 </div>

//                                 <div className='dataTables_info text-dark'>
//                                     Records: {checked.length}
//                                 </div>
//                                 {checked.length > pageSize ? <div className="dataTables_paginate paging_simple_numbers justify-content-center  mb-0 pt-2"
//                                     id="example-student_wrapper">
//                                     <Pagination count={number.length} variant="outlined" color='primary' size="small" page={currentPage} siblingCount={1} boundaryCount={2} onChange={(e, p) => setCurrentPage(parseInt(p))} />
//                                 </div> : null}
//                             </div>
//                             <div className="table-responsive full-data" style={{ paddingBottom: "1px", transform: 'rotateX(180deg)' }}>
//                                 <div id="example-student_wrapper" className="dataTables_wrapper no-footer">
//                                     <table className="table-responsive-lg table display dataTablesCard student-tab
//                                     dataTable no-footer mt-0 mb-0" style={{ transform: 'rotateX(180deg)' }} id="example-student" >
//                                         <thead style={{ backgroundColor: "#cccccc" }} >
//                                             <tr className='text-center  border border-left'>
//                                                 <th className=' custom-table-header p-2'>SN</th>
//                                                 {/* <th className=' custom-table-header p-2'>Order No.</th> */}
//                                                 <th className=' custom-table-header p-2'>
//                                                     <input type="checkbox" className="form-check-input" id="checkAll"
//                                                         /*   checked={!unchecked} */
//                                                         onClick={() => handleCheckedAll(unchecked, checked, setChecked, setUnChecked)}
//                                                     />
//                                                 </th>
//                                                 <th className='p-2 text-start custom-table-header  '>STATUS</th>
//                                                 <th className=' text-start custom-table-header p-2' >TEST TITLE</th>
//                                                 <th className=' text-start custom-table-header p-2' >QUESTIONS</th>
//                                                 <th className='p-2  custom-table-header'>START DATE</th>
//                                                 <th className='p-2  custom-table-header'>END DATE</th>
//                                             </tr>
//                                         </thead>
//                                         <tbody>
//                                             {records.length > 0 ? records.map((item, ind) => (
//                                                 <tr key={ind} className={`${ind % 2 == 1 ? 'custom row-height' : 'row-height'}`} >
//                                                     <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0" > {item.sn}</h6></td>
//                                                     <td className='text-center p-1 column-width' style={{ border: "1px solid #cccccc", width: "65px" }}>
//                                                         <div className="checkbox me-0 align-self-center">
//                                                             <div className="custom-control custom-checkbox ">
//                                                                 <input type="checkbox"
//                                                                     className="form-check-input"
//                                                                     id={`stud-${item.sn}`}
//                                                                     checked={item.inputchecked}
//                                                                     onChange={() => handleChecked(item.sn, setChecked, checked)}
//                                                                 />
//                                                                 <label className="custom-control-label" htmlFor={`stud-${item.sn}`}></label>
//                                                             </div>
//                                                         </div>
//                                                     </td>
//                                                     <td className='text-start p-1 active-col-width' style={{ border: "1px solid #cccccc", width: "80px" }}>
//                                                         {item.status === 1 && <h6 className="text-success mb-0 ">Active </h6>}
//                                                         {item.status == 0 && <h6 className="text-danger mb-0">Inactive </h6>}
//                                                     </td>
//                                                     <td className='p-1' style={{ border: "1px solid #cccccc" }}>
//                                                         <div className="d-flex justify-content-between ">
//                                                             <h6 className='mb-0 ' style={{ fontSize: '13px', fontWeight: 'bold' }}>{item.title}
//                                                                 <CiEdit cursor='pointer' title='Edit Subject' size={18} className='ms-3' /* onClick={() => handleUpdateYear(item)}  */
//                                                                     onClick={() => childRef.current.openModal(item)} />
//                                                                 <MdDeleteOutline color="red" title='Delete Subject' cursor='pointer' size={18} className='ms-2' onClick={() => { deleteItem(item.sn, 'Subject', '/hideBookSubject/', setSubjectLoading, loadSubjects) }}

//                                                                 />
//                                                             </h6>
//                                                         </div>
//                                                     </td>
//                                                     <td className='text-center p-1 column-width ' style={{ border: "1px solid #cccccc", width: "65px" }} ><h6 className="sn-font-weight mb-0" > <Badge bg="" className='badge-primary light badge-sm ms-2 cursor-pointer pe-3 ps-3' onClick={() => navigate(`/book-questions`, { state: { packageId: item.packageId } })} >{item.questionCount || 0}</Badge> </h6></td>
//                                                     <td className='text-center date-column-width  p-1' style={{ border: "1px solid #cccccc", width: "85px" }}><h6 className="mb-0">{new Date(item?.publishDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td>
//                                                     <td className='text-center date-column-width  p-1' style={{ border: "1px solid #cccccc", width: "85px" }}><h6 className="mb-0">{new Date(item?.endDate?.replace(/\s+/g, ""))?.toLocaleDateString('en-GB')}</h6></td>
//                                                 </tr>
//                                             )) :
//                                                 <tr>
//                                                     <td colSpan="9" className="text-center">
//                                                         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
//                                                             {
//                                                                 showTableResponse(subjects, checked)
//                                                             }
//                                                         </div>
//                                                     </td>
//                                                 </tr>}
//                                         </tbody>
//                                     </table>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <BookTestModal ref={childRef} loadSubjects={loadSubjects} setSubjectLoading={setSubjectLoading} />
//         </>
//     )
// }

// export default PracticeTests;

