import { toast } from "react-toastify";
import { BACKEND_SERVER_URL } from "../../../http/http-config";
import swal from "sweetalert";
import axios from "axios";
import { Spinner } from "react-bootstrap";

export async function bulkOperations(action, records, apiName, moduleName, setItemsLoading, loadItems) {
    let ids = records.filter(record => record.inputchecked).map(record => record.sn);
    console.log(action, records, apiName, moduleName, setItemsLoading, loadItems)
    if (ids.length <= 0) {
        toast.error(`Please select atleast one item`);
        return;
    }
    const postData = { ids: ids };
    switch (action) {
        case "delete":
            swal({
                title: "Are you sure?",
                text: `Once deleted, you will not be able to recover these ${moduleName}`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((yes) => {
                if (yes) {
                    axios.post(BACKEND_SERVER_URL + apiName, postData).then((response) => {
                        if (response) {
                            toast.success(response.data.message);
                            setItemsLoading(!loadItems)
                        }
                    }).catch((error) => {
                        toast.error(error.message);
                    });

                }
            })
            break;
        case "activate":
            swal({
                title: "Are you sure?",
                text: `Do you really want to activate these ${moduleName}`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((yes) => {
                if (yes) {
                    axios.post(BACKEND_SERVER_URL + apiName, postData).then((response) => {
                        if (response) {
                            toast.success(response.data.message);
                            setItemsLoading(!loadItems)

                        }
                    }).catch((error) => {
                        toast.error(error.message);
                    });

                }
            })
            break;
        case "InActivate":
            swal({
                title: "Are you sure?",
                text: `Do you really want to inactivate these ${moduleName}`,
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((yes) => {
                if (yes) {
                    axios.post(BACKEND_SERVER_URL + apiName, postData).then((response) => {
                        if (response) {
                            toast.success(response.data.message);
                            setItemsLoading(!loadItems)

                        }
                    }).catch((error) => {
                        toast.error(error.message);
                    });

                }
            })
            break;
    }
}

export async function deleteItem(id, itemName, apiName, setItemsLoading, loadItems) {
    swal({
        title: "Are you sure?",
        text: `Once deleted, you will not be able to recover this ${itemName}`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
    }).then((willDelete) => {
        console.log(BACKEND_SERVER_URL + apiName + id)
        if (willDelete) {
            axios.delete(BACKEND_SERVER_URL + apiName + id).then((res) => {
                if (res) {
                    swal(`Poof! Your ${itemName} has been deleted!`, {
                        icon: "success",
                    });
                    setItemsLoading(!loadItems);
                }
            }).catch(error => {
                console.log(error.response.data)
                toast.error(error.response.data.message)
            })
        }
    })
}
export async function handleChecked(sn, setChecked, checked) {
    let temp = checked.map((data) => {
        if (sn === data.sn) {
            return { ...data, inputchecked: !data.inputchecked };
        }
        return data;
    });
    setChecked(temp);
}

export async function handleCheckedAll(value, checked, setChecked, setUnChecked) {
    let temp = checked.map((data) => {
        return { ...data, inputchecked: value };
    });
    setChecked(temp);
    setUnChecked(!value);
}
export async function handleSearch(value, setChecked, dataArray, searchingProperty) {
    const filteredItems = dataArray?.filter(
        item => item?.[searchingProperty]?.toLowerCase().includes(value?.toLowerCase())
    );
    console.log(filteredItems)
    setChecked(filteredItems)
}
export function showTableResponse(totalItems, checked) {
    if (totalItems && totalItems.length < 1) {
        return <Spinner animation="grow" variant="info" />
    } else if (totalItems.length > 0 && checked.length < 1) {
        return <h3>No Data Found</h3>
    }
}

export async function checkExistingItem(tableName, columnName, itemNameValue) {
    try {
        let response = await axios.post(BACKEND_SERVER_URL + '/check-duplicate-item', { tableName, columnName, itemNameValue })
        if (response.data.isExists) {
            toast.warn("Item Already Exists")
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.log(error)
    }
}

export async function getQuizSubjectsForDropdowns() {
    try {
        const res = await axios.get(BACKEND_SERVER_URL + '/getSubjectForSectionsDropdown');
        let subjects = res.data.subjects;
        return subjects;
    } catch (error) {
        console.log(error)
        toast.error(error.response.data.message)
    }
}
export async function getQuizSectionsForDropdowns() {
    try {
        const res = await axios.get(BACKEND_SERVER_URL + '/getSectionsForDropdown');
        let sections = res.data.sections;
        return sections;
    } catch (error) {
        console.log(error)
        toast.error(error.response.data.message)
    }
}

export async function getTotalQuizQuestionCount() {
    return (await axios.get(BACKEND_SERVER_URL + '/getTotalQuizQuestionCount')).data.questionCount
}
export async function getQuizQuestions(questionOffset) {
    return (await axios.get(BACKEND_SERVER_URL + '/getQuizQuestions/' + questionOffset)).data.questions
}
export function sortInAlphabeticalOrder(array, title) {
    array.sort((a, b) => {
        // Convert subjects to lowercase to ensure case-insensitive sorting
        const titleA = (a[title] && typeof a[title] === 'string') ? a[title].toLowerCase() : '';
        const titleB = (b[title] && typeof b[title] === 'string') ? b[title].toLowerCase() : '';
        // Compare subjects
        if (titleA < titleB) {
            return -1;
        }
        if (titleA > titleB) {
            return 1;
        }
        return 0; // subjects are equal
    });
    return array;
}