import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { BACKEND_SERVER_URL } from '../../../http/http-config';
import { toast } from 'react-toastify';

const FileInput = ({ uploadedFileURL, handleChangeCallBack, labelName, inputType, inputName, accept, isMandatory }) => {
    const handleChange = (e) => {
        //  console.log(e.target.value, e.target.name)
        handleChangeCallBack(e.target.name, e.target.value)
    }
    const [isFileUploading, setIsFileUploading] = useState(false);


    const [selectedFile, setSelectedFile] = useState(null);


    const fileInputRef = useRef(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);

    };
    const handleUploadFile = () => {
        fileInputRef.current.click(); // Trigger click event on file input
    };
    useEffect(() => {
        if (selectedFile) {
            setIsFileUploading(true);          
            //upload image on server
            const formData = new FormData();
            formData.append('uploadedFile', selectedFile, selectedFile.name);
            axios.post(BACKEND_SERVER_URL + '/uploadFile', formData)
                .then((response) => {
                    if (response.data.statusCode == 201) {
                        handleChangeCallBack(inputName, response.data.imagePath);
                            setIsFileUploading(false);                     

                    }
                })
                .catch(error => {
                    setIsFileUploading(false);
                    toast.error(error.response.data.message);
                });
        }
    }, [selectedFile])

    return (
        <>
            <div className="col-xl-2 col-sm-2 pt-3">
                <div className="mb-3" >
                    <label htmlFor={labelName} className="form-label text-primary">{labelName}{isMandatory && <span className="required">*</span>}</label>
                </div>
            </div>
            <div className="col-xl-10 col-sm-10  d-flex  justify-content-between  ">
                <div className="mb-3 col-10 " >
                    <input type={inputType} id={labelName} className='form-control' name={inputName} value={uploadedFileURL} onChange={handleChange} />
                    <div className="col-10">
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            accept={accept}
                            onChange={handleFileChange}
                            className='form-control' />
                    </div>
                    <div className="card product-grid-card" style={{ display: uploadedFileURL && inputName == 'imageURL' ? 'block' : 'none' }}>
                        <div className="card-body">
                            <div className="new-arrival-product">
                                <div className="new-arrivals-img-contnent">
                                    <img className="img-fluid" src={uploadedFileURL} alt="" style={{ height: "15rem" }} />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-2 d-flex justify-content-start alignf-items-center" >
                    {
                        isFileUploading
                            ?
                            <div className="p-2">
                                <Spinner size='md' animation="border" variant="info" />
                            </div>

                            :
                            <Button className='' style={{ height: "40px" }} variant="outline-info btn-square" onClick={handleUploadFile}>Upload File</Button>
                    }

                </div>


            </div>
        </>
    );
}

export default FileInput;
